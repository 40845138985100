footer{
    background-color: #ECDBCB;
    max-width: 100%;
}
.footerTop{
    display: flex;
    justify-content: space-between;
    padding: 5% 0 5% 5%;
    width: 100%;
}
.fT{
    width: 80%;
}
.fT > h5{
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
}
.fT > p{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

}

.socialIcons{
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    width: 75%;

}

input, input:focus, input:active, textarea, textarea:focus, textarea:active{
    border: 1px solid #555555;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 90%;
    height: 100%;
    padding:0.5rem;
    color: #000000;
}
input:focus, input:active{
    border: solid 1px blue
}
.subBtn{
    width: 173px;
    height: 46px;
    top: 8625px;
    left: 844px;
    border-radius: 10px;
    background-color: #292D32;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: #ffffff;
}
.footerBottomLeft > h3{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.footerBottomLeft > p{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #D4A80A;

}
.contDiver{
    width: 100%;
}
.dividd{
    color:#D4A80A;
    border: none;
    height: 50px;
}
.footerBottom{
    display: flex;
    justify-content: space-between;
    padding: 5%;
}
.bM{
    display: flex;
}
.bM2{
    margin-top: 20%;
}


.sIc{
    background-color: #EEEEEE;
    border: 1px solid #D4A80A;
    border-radius: 50%;
    height: 30px;
    margin-right: 10%;
    width: 30px;
    justify-content: center;
}
.sIc > img{
    width: 100%;
    height: 100%;
    padding: 25%;
}
.sameSize{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}
.sameSize:visited {
  text-decoration: none;
  color:#000000
}
.same2{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 275;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}
.footerBottomRight > ul > a > li {
    padding-bottom: 10%;
}
.copyright{
    padding: 3% 5% ;
}
/* .bbb{
    height: 50px;
    background-color: #232323;
    margin-top: -3%;
} */


@media only screen and (max-width: 600px) {
    .footerBottom{
        display: grid;
    }
    .socialIcons{
        justify-content: left;
    }
    .socialIcons li{
        padding-right: 5%;
    }
    .bM{
        margin-top: 15%;
    }
    .bM2{
        margin-top: 5%;
    }
    .footerBottomRight{
        padding-top: 10%;
        text-align: left;
    }
    .footerBottomLeft > h3{
        font-family: Poppins;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
    }
    .footerBottomLeft > p{
        font-family: Poppins;
        font-size: .8rem;
        font-weight: 400;
        line-height: 5px;
        letter-spacing: 0em;
    }
    .sameSize{
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 300;
    }
    .same2{
        font-family: Poppins;
        font-size: .7rem;
        font-weight: 275;
        line-height: 15px;
    }
    .footerBottomMiddle{
        width: 100%;
    }
    .copyright{
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
    }
    .fT{
        width: 100%;
    }
    .fT > h5{
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 300;
        line-height: 18px;
    }
    .fT > p{
        font-family: Poppins;
        font-size: .7rem;
        font-weight: 275;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;

    }
}