.testimonies {
	background-color: #232323;
	color: #fff;
    display: grid;
    place-items: center;
    padding-top: 20%;
    padding-bottom: 30%;
}
.revCont,
.testTop {
	display: flex;
}
.testTop > h4{
    padding: 5%;
}
.abts {
	font-family: Poppins;
	font-size: 50px;
	font-weight: 1000;
	line-height: 75px;
	letter-spacing: 0em;
	color: #000000;
}
.testTxxt{
    padding-bottom: 25%;
}
.testImgC {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	border: 1px solid #ffffff;
}
.testImgCenter{
    height: 100px;
	width: 100px;
	border: 1px solid #000000;
}
.testImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.aboutOutCont{
	background-color: #232323;
	padding: 0% 5%;
}
.aboutOut {
	background-color: #ecdbcb;
	padding: 5%;
	color: #000000;
}
.revCont{
	padding-left: 10%;

}
.revieW {
	width: 27.5%;
	height: 412px;
	top: 7355px;
	left: 122px;
	background-color: #ffffff5e;
    text-align: left;
    padding: 5% 2%;
    margin-top: 5%;
    color: #ffffff;
}
.rBtn{
    background-color: #E3D081B2;
	width: 50%;
	height: 100px;
    margin-top: 5%;
	border-radius: 15px;
	border: 0.5px;
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}
.revieW > p{
    padding: 10%;
}
.reviewMiddle{
    background-color: #ffffff;
    width: 35%;
    margin-top: 0;
	height: 520px;
	color: #000000;
}

.about {
	display: flex;
}
.aboutText {
	width: 60%;
	font-family: Poppins;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 3rem;
	letter-spacing: 0em;
	text-align: left;
	padding: 5%;
}
.readMore {
	width: 25rem;
	height: 6rem;
	top: 4406px;
	left: 181px;
	border-radius: 15px;
	background-color: #3A3A3A;
	border: 1px solid #E3D081;
	color: #E3D081;
	border: 3px;
}
.readMore:hover{
	border: 1px solid #3A3A3A;
}
.aboutPics {
	width: 40%;
	height: 100%;
	padding: 5%;
}
.aboutPics > img{
	width: 100%;
	height: 100%;
}
.about-col{
	display: flex;
}


.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.slide >img {
  max-width: 100%;
  max-height: 300px;
}

.slider > button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.slider > button:hover {
  background-color: #0056b3;
}

.revContMobile{
	display: none;
}

.leftRight2{
	display: flex;
  	width: 95%;
	justify-content: space-between;
	position: absolute;
	margin-top: -40%;
}
.gtlT2{
  background-color: #3A3A3AA6;
  padding: 2%;
  font-size: 1rem;
  border:none;
  color: #FFFFFF;
}


@media only screen and (max-width: 600px) {
	/* .revieW > p{
		font-size: 20px;
		line-height: 15px;
	} */
	.revCont{
		display: none;
	}
	.revContMobile, .allTest{
		display: grid;
		width: 100%;
		padding: 0;
	}
	.testTop{
		padding: 0 10%;
	}
	.allTest{
		padding-bottom: 10%;
	}
	.revieW, .reviewMiddle{
		width: 100%;
		height: 100%;
		margin-top: 0;
	}
	.aboutText {
	/* width: 60%; */
	font-family: Poppins;
	font-size: 10px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;

	}
	.abts {
		font-family: Inter;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
	}
	.readMore {
		width: 8rem;
		height: 2rem;
		top: 4406px;
		left: 181px;
		border-radius: 5px;
		background-color: #3A3A3A;
		border: 1px solid #E3D081;
		color: #E3D081;
		border: 3px;
	}
	.rBtn{
		height: 47px;
		width: 244px;
		font-family: Inter;
		font-size: 1rem;
		font-weight: 400;
		line-height: 15px;
		border-radius: 5px;

	}
}