.collections{
    background-color: #232323;
    padding-bottom: 20%;
}
.colTop{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 517px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.colTitle{
    font-family: Poppins;
    font-size: 80px;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: center;
    color: #ECDBCB;
}
.colTitle2{
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #ECDBCB;
}
.colBottom{
    padding: 10%;
}
.colCard{
    margin-top: 10%;

}
.colCardTopImg{
    width: 100%;
}
.colCardTop{
    width: 100%;
}
.colCardBottom{
    background-color: #232323;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 5%;
    width: 100%;
}
.colCardSet{
    font-family: Inter;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}
.colCardName{
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.colCardPrice{
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #E3D081B2;
}
.oldPrice{
    color: #ffffff;
}
.dBtn2{
    width: 80%;
}

@media only screen and (max-width: 600px) {
    .colCardTop{
        height: 300px;
    }
    .colTitle{
        font-size: 3rem;
    }
    .colTitle2{
        font-size: 1rem;
    }
}