.shopNow{
   padding: 2% 5%;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 35px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    border:none;
    background-color: #E3D081;
}
.heroParent {
  /* background-image: url("../assets/heroBG.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  width: 100%;
  height: 700px;
}

.heroChild {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(128, 128, 128, 0.5);
  /* background-color: rgba(189, 162, 162, 0.5);  */
}
.heroInner{
  width: 80%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.conf{
  font-family: Poppins;
  font-size: 60px;
  font-weight:bolder;
  /* line-height: 90px; */
  letter-spacing: 0em;
  /* text-align: left; */
  color: #FFFFFF;

}
.saunt{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  /* line-height: 60px; */
  letter-spacing: 0em;
  /* text-align: left; */
  color: #D9D9D9;

}

@media only screen and (max-width: 600px) {
  .heroParent{
    height: 400px;
  }
  .conf{
    font-size: 20px;
    font-weight: 800;
  }
  .saunt{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .shopNow{
    font-family: Poppins;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
}