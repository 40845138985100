.fa-angle-down{
    padding-left: 0%;
}
.header1{
    background-color: #232323;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 30px 50px 0px 50px;
}
.callus, .callus:hover, .callus:active, .callus:visited{
    color: #ffffff;
}
.cUsc{
    color: #ffffff !important;
}
.cBox{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.cartBox{
    background: url(../assets/cart.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2.5rem;
    width: 2rem;
    text-align: center;
    padding: 8%;
    /* padding-bottom: 0; */
    margin-left: 15%;
    margin-top: -10%;
}
.header2{
    height: 98px;
    background-color:#ECDBCB;
    /* padding-top: 57px; */
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 50px 0px 50px;
    z-index: 10000;
}
.sticky{
    position: fixed;
    width: 100%;
    top: 0;
}
.hamMenu{
    cursor: pointer;
}
.faawlogo {
  margin: auto;
  width: 50%;
}
.faawlogo > img{
    height: 100%;
}

.hoF{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #3A3A3A;

}

.normalMenu{
  display: flex;
  justify-content: space-between;
}
.lks {
    font-family: Poppins;
    font-size: 18px;
    display: flex;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    justify-content: space-between;
}
.lks:hover, .sameSize:hover, .cBox:hover, .hoF:hover{
    font-weight: bolder;
}
a:link {
  text-decoration: none;
}

.lks:visited {
  text-decoration: none;
  color:#000000
}
a.active {
  text-decoration: none;
  color: #D4A80A !important;
}
.ic{
    padding-left: 50%;
}

.navbarss{
    width: 50%;
}
.hd{
    color: #ECDBCB;
}
.cls{
    display: none;
}
.dis{
    position:absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: #ffffff;
    padding: 12px 20px;
    z-index: 1;
    margin-top: 4%;
    width: 20%;
}
.nodis{
    display:none
}
.ddbl{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    list-style:circle;
}
.ddsl{
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 38px;
    letter-spacing: 0em;

}

.hamburgerMenu{
    display: flex;
}
.hamburgerImg >img{
    height: 100%;
}
.hamburgerImg{
    padding-top: auto;
    display: none;
    height: 30%;
    margin-top: 5%;
}


@media only screen and (max-width: 600px) {
    .hamburgerMenu{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .hamburgerImg{
        display: grid;
    }
    .normalMenu{
        display: none;
    }

    .hd{
        display: none;
    }
    .showMenu{
        display: grid;
        padding-top: 25%;
        padding-bottom: 5%;
        position: fixed;
        left: 0;
        top:-10%;
        margin-top: 5%;
        z-index: 100000000000;
        background-color: #222222;
        width: 100%;
        color: #ffffff;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        /* height: 50vh; */
    }
    .showMenu > a{
        color: #ffffff;
    }

    .header1{
        font-size: 10px;
        height: 40px;
        padding: 10px 10px 0px 10px;
    }
    .header2{
        padding: 10px 10px 0px 10px;
    }
    .oSt{
        height: 70%;
    }
    .header2{
        height: 50px;
        line-height: 10px;
    }
    .faawlogo{
        height: 20px;
    }
    .hoF{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
    .cBox > p:nth-child(1){
        font-size: 1rem;
        display: none;
    }
    .lks{
        width: 90%;
        border-bottom: .1px solid #ffffff;
    }
    .dis{
        position:absolute;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: #222222;
        padding: 12px 20px;
        z-index: 1;
        width: 80%;
    }
    .ddsl{
        color: #ffffff;
    }
    .cartBox{
        padding-top: 50%;
        height: 2rem;
        font-size: 1rem;
    }
    .hamMenu2{
        width: 150%;
    }
}