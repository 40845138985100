.cartOverlay {
	width: 100%;
	background-color: #232323;
	display: grid;
	place-items: end;
	color: #ffffff;
}
.sCart,
.eCartBody {
	width: 100%;
	height: 100%;
	padding-bottom: 30%;
}
.shoppingCartDiv {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ecdbcb;
	margin: 0% 10% 5% 10%;
}
.shoppingCartText,
.times {
	font-family: Poppins;
	font-size: 30px;
	font-weight: 300;
	line-height: 45px;
	padding: 10% 0% 5% 0%;
	letter-spacing: 0em;
	text-align: left;
}
.times,
.deleteItem {
	cursor: pointer;
}
.itemRow {
	display: flex;
	justify-content: space-between;
	padding: 3% 10% 0% 10%;
}
.itemRowCol1 {
	display: flex;
	width: 40%;
	padding: 0;
}
.itemImgCont {
	height: 70%;
	width: 40%;
}
.itemImg {
	width: 100%;
	height: 100%;
	border-radius: 15px;
}
.itemRowText {
	width: 70%;
	padding-left: 10%;
}
.itemRT1 {
	font-family: Inter;
	font-size: 18px;
	font-weight: 300;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}
.itemRT2 {
	font-family: Inter;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.cartBoxBottom {
	display: flex;
	justify-content: space-between;
	padding: 2% 0% 0% 1%;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
	margin: 0% 10%;
	font-family: Inter;
	font-size: 30px;
	font-weight: 400;
	line-height: 36px;
	letter-spacing: 0em;
	text-align: left;
}
.cartBoxCtn {
	display: grid;
	place-items: center;
	padding-top: 10%;
}
.cartBoxBtn,
.noItemBtn {
	border: 1px solid #e3d081;
	border-radius: 10px;
	margin: 0% 5% 2% 5%;
	background-color: #3a3a3a;
	color: #e3d081;
	padding: 1%;
	width: 60%;
	font-family: Inter;
	font-size: 2rem;
	font-weight: 400;
	line-height: 48px;
}
.cartBoxBtn2,
.noItemBtn {
	background-color: #e3d081;
	color: #000000;
}
.noItemBtn {
	padding: 2%;
}

.noItem {
	text-align: center;
	width: 100%;
}
.noItm {
	margin: 20% 0% 10% 0%;
	font-family: Inter;
	font-size: 40px;
	font-weight: 400;
	line-height: 48px;
	letter-spacing: 0em;
}

/* Cart Edit */
.eCart {
	background-color: #3a3a3a;
	text-align: left;
	justify-content: left;
	color: #ffffff;
}
.cartCart {
	font-family: Poppins;
	font-size: 50px;
	font-weight: 500;
	line-height: 75px;
	letter-spacing: 0em;
	text-align: left;
	color: #e3d081b2;
	padding: 2% 0;
}
.emptyBlack {
	background-color: #232323;
	height: 70px;
}
.eCartBody {
	padding: 0.3% 5%;
}
.tableWrapper {
	border: 1px solid #ecdbcb;
	border-radius: 15px;
	margin-bottom: 30%;
}
.tWrapper {
	overflow-x: auto;
	overflow-y: hidden;
}
.table {
	width: 100%;
	color: #ffffff;
	overflow-x: auto;
	/* padding: 25%; */
}
.tableP0 {
	font-family: Poppins;
	font-size: 30px;
	font-weight: 500;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: left;
}
.tableR {
	display: flex;
}
.itemImgCont2 {
	display: flex;
	width: 160px;
	height: 147px;
	margin-right: 5%;
}
.tX {
	margin-right: 15%;
}
.tableP {
	font-family: Poppins;
	font-size: 22px;
	font-weight: 500;
	line-height: 33px;
	letter-spacing: 0em;
	text-align: left;
}
.tableP2 {
	font-family: Poppins;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}
.product {
	text-align: center;
}
.sS {
	color: #d4a80ab2;
}
.price {
	color: #e3d081b2;
}

.pad {
	padding-top: 25%;
}
.upC {
	width: 100%;
	display: flex;
	justify-content: space-around;
	/* text-align: end; */
}
.upC > button {
	width: 20%;
	height: 50px;
	margin: 1% 5% 1% 0;
	border-radius: 7.5px;
	font-family: Poppins;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 45px;
	letter-spacing: 0em;
	color: #000000;
	background-color: #e3d081;
}

/* check out  */
.dAdd {
	font-family: Poppins;
	font-size: 35px;
	font-weight: 400;
	line-height: 53px;
	letter-spacing: 0em;
}
.checkoutInput,
.checkoutInput:focus,
.checkoutInput:active,
.checkoutTextArea,
.checkoutTextArea:focus,
.checkoutTextArea:active {
	height: 50px;
	width: 100%;
	height: 114px;
	font-size: 2rem;
	border-radius: 15px;
}
.checkoutTextArea,
.checkoutTextArea:focus,
.checkoutTextArea:active {
	height: 60%;
}
.checkoutRadio,
.checkoutRadio:focus,
.checkoutRadio:active {
	width: 50px;
	height: 15px;
	background-color: transparent;
}
.lbl {
	color: #ffffffd4;
	font-family: Poppins;
	font-size: 35px;
	font-weight: 500;
	line-height: 53px;
	letter-spacing: 0em;
	text-align: left;
}
.checkoutBottom {
	background-color: #2c2c2c;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 5% 0 15% 0;
}
.checkoutBottomR {
	background-color: #3a3a3a;
	width: 60%;
	padding: 5%;
}
.fle {
	display: flex;
	justify-content: space-between;
}
.bankStuff {
	background-color: #ffffff;
	color: #000000;
	width: 80%;
	font-family: Poppins;
	padding: 3% 5%;
	border-radius: 15px;
	font-size: 1.5rem;
	font-weight: 300;
	text-align: center;
}
.cardOption {
	width: 80%;
}
.guara2 {
	background-color: #3a3a3a;
}
.bankStuff > button {
	background-color: #e3d081b2;
}
.generateBtn {
	width: 80%;
	background-color: #e3d081;
	padding: 2%;
	border-radius: 15px;
}
.cl {
	color: #e3d081b2;
}
.yourOrder {
	font-family: Poppins;
	padding-bottom: 10%;
	font-size: 35px;
	font-weight: 500;
	line-height: 53px;
	letter-spacing: 0em;
	text-align: left;
}
.yourProduct {
	font-family: Poppins;
	font-size: 30px;
	font-weight: 500;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: left;
}
.st1 {
	font-family: Poppins;
	font-size: 22px;
	font-weight: 500;
	line-height: 33px;
	letter-spacing: 0em;
	text-align: left;
}
.st2 {
	font-family: Poppins;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}

.nT {
	font-family: Inter;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}
.nTT {
	font-family: Inter;
	font-size: 25px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}
.tota {
	font-family: Poppins;
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: left;
}
.makeSure {
	font-family: Poppins;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: left;
	color: #ffffffd4;
}
.toT {
	font-family: Poppins;
	font-size: 22px;
	font-weight: 500;
	line-height: 33px;
	letter-spacing: 0em;
	text-align: left;
}
.sS2 {
	color: #e3d081b2;
}
.tory {
	font-family: Poppins;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: left;
}
.tory2 {
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 300;
}

@media only screen and (max-width: 600px) {
	.tableP0 {
		font-size: 20px;
	}
	.tableP,
	.tableP2 {
		font-family: Poppins;
		font-size: 15px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: 0em;
		text-align: left;
	}
	.itemImgCont {
		width: 200px;
	}
	.upC > button {
		width: 40%;
		height: 50px;
		margin: 1% 5% 1% 0;
		border-radius: 7.5px;
		font-family: Poppins;
		font-size: 15px;
		line-height: 15px;
	}
	/* .plus2{
		width:30%
	} */
	.itemImgCont2 {
		padding-right: 20%;
	}
	.changNum2 {
		padding-top: 15%;
	}
	.cartCart {
		font-size: 2.5rem;
	}
	.dAdd,
	.yourOrder,
	.tota,
	.shoppingCartText {
		font-size: 1.5rem;
	}
	.lbl,
	.yourProduct,
	.st1,
	.st2,
	.nT,
	.nTT,
	.makeSure,
	.guara2,
	.bankStuff > p,
	.tory,
	.noItm,
	.cartBoxBtn,
	.noItemBtn {
		font-size: 1rem;
	}
	.checkoutInput,
	.checkoutInput:focus,
	.checkoutInput:active,
	.checkoutTextArea,
	.checkoutTextArea:focus,
	.checkoutTextArea:active {
		height: 50%;
		font-size: 1rem;
		border-radius: 5px;
	}
	.checkoutBottomR {
		width: 100%;
	}
	.generateBtn {
		font-size: 16px;
	}
	.tory {
		padding-top: 5%;
		line-height: 20px;
	}
	.tory2 {
		font-size: 0.7rem;
	}
	.itemRT1 {
		font-size: 1rem;
		font-weight: 300;
		line-height: 22px;
	}
	.itemRT2 {
		font-size: 1.2rem;
		font-weight: 400;
		line-height: 24px;
	}
	.itemRowCol1 {
		width: 70%;
	}
	.cartBoxBottom > p {
		font-size: 1rem;
	}
}
