.designs {
	background-color: #232323;
	color: #fff;
	padding: 10% 5%;
}
.designsMiddle{
	padding: 5%;
}
.ds1 {
	text-align: center;
}
.col-Img{
	width: 100%;
	height: 100%;
}
.col-Img > img{
	width: 100%;
	height: 100%;
}
.col-btn{
	width: 100%;
	background-color: #E3D081B2;
	top: 1956px;
	left: 170px;
	font-family: Inter;
	font-size: 30px;
	font-weight: 600;
	line-height: 1rem;
	text-align: center;
	border-radius: 15px;
	padding: 5%;
}
.dT1 {
	font-family: Poppins;
	font-size: 50px;
	font-weight: 700;
	line-height: 75px;
	letter-spacing: 0em;
	color: #ecdbcb;
}
.dT2 {
	font-family: Poppins;
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: center;
	color: #ffffff;
}
.dT11 {
	margin-bottom: 5%;
	font-family: Poppins;
	font-size: 30px;
	font-weight: 400;
	line-height: 45px;
	letter-spacing: 0em;
	text-align: center;
}
.cY{
	width: 30%;
}
.cZ {
	width: 100%;
	/* height: 847px; */
    margin: 5% 0 5% 0;
}
.btS {
	background-color: #ffffff;
	padding: 5%;
}
.dBtn {
	background-color: #E3D081;
	width: 100%;
	border-radius: 15px;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
	padding: 5%;
	border: 0.5px solid #D4A80A;
}

.btS2{
    background-color: #232323;
}
.catName{
    font-size: 2rem;
    font-weight: 500;
    line-height: 61px;
    letter-spacing: 0em;
	color: #ECDBCB;
}
.btS2 > button{
    width: 100%;
    height: 96px;
    border-radius: 15px;

}
.sImgCont{
    height: 699px;
}
.sImage {
	width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px){
	.sImgCont{
		height: 218px;
	}
	.dT1 {
		font-family: Inter;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 0em;
	}
	.dT11 {
		font-family: Poppins;
		font-size: 12px;
		font-weight: 500;
		line-height: 18px;
	}
	.catName{
		font-family: Inter;
		font-size: 1rem;
		font-weight: 500;
		line-height: 15px;
		letter-spacing: 0em;
		text-align: left;
	}
	.vOp{
		font-family: Poppins;
		font-size: 12px;
		width: 108px;
		height: 28px;
		border-radius: 5px;
		padding-bottom: 15%;
	}
	.col-Img{
		height: 189px;
	}
	.col-btn{
		height: 38px;
		font-family: Inter;
		font-size: 12px;
		font-weight: 600;
		line-height: 1px;
		border-radius: 5px;
	}
}