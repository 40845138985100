@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');
* {
    margin: 0;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
}
body{
  min-height: 100vh;
  max-width: 100%;
}


li{
    list-style-type: none;
}

a:link {
  text-decoration: none;
  color:#000000
}

a:visited {
  text-decoration: none;
  color:#000000
}
.skill{
  cursor: pointer;
}
a:hover, .skill:hover {
  text-decoration: none;
  font-weight: bolder;
}

/* a:active {
  text-decoration: none;
  color: #D4A80A !important;
    font-size: 100px;
} */

.custom-toast{
  z-index: 1000000;
  background-color: red;
}
button:hover{
  background-color: #ECDBCB;
  color: #000000;
}
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 2s ease;
}
.reveal.showAnim{
  transform: translateY(0px);
  opacity: 1;
}