.cartTopRight{
  padding: 0 5%;
}
.conTop{
  position: relative;
}
.leftRight{
  display: flex;
  width: 96%;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  /* margin-top: -75%; */
}
.gtlT{
  background-color: #3A3A3AA6;
  padding: 2%;
  font-size: 3rem;
  border:none;
  color: #FFFFFF;
}
.cartName{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}
.cartPrice{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color:#E3D081
}
.cartAvailable{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ECDBCB;

}
.cartHurray{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #EEEEEE;
}
.cartInstock{
  color: #30BD57;
  padding-left: 2%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.cartHr1{
  color: #BDBDBD;
  border: 1px solid #BDBDBD;
}
.addtocart{
    background-color: #232323;
    color: #ffffff;
    padding: 5%;
}
.cartImg{
    width: 100%;
    height: 855px;
    border-radius: 15px;
}
.colorCircle{
    /* border: 1px solid #fff; */
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    margin: 1%;
    cursor: pointer;
}
.circleSelected{
  border: 2px solid #fff;
  padding: 1px;
}
.selTag{
    color: #E3D081;
}

.cls{
    display: flex;
    color: #ECDBCB;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

}
.selectionOption{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #3A3A3AA1;
}

select {
  width: 400px;
  height: 69px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border-radius: 15px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding: 3%;

}

select option{
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 25px;
}

  select option:checked {
    background-color: #D4A80A;
    color: white;
  }

.changNum{
    display: flex;
    padding: 3% 0;
}
  .plus, .num{
    background-color: #EEEEEE;
    color: #000;
    width:3rem;
    height: 32px;
    border: 1px solid #BDBDBD;
    gap: 10px;
    text-align: center;
    cursor: pointer;

  }
  .num{
    cursor:default;
  }
.cartBtns{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 15%;
    padding-bottom: 3%;
}
.cartBtn{
  padding: 2%;
  margin-right: 3%;
  border: none;
}

.addCartBottom{
    height: 500px;
    display: flex;
    padding-top: 5%;
    justify-content: space-between;
}
.btRight{
  padding: 5%;
}
.cartCard{
    display: flex;
    justify-content: center; /* Horizontally center the image */
    align-items: center; /* Vertically center the image */
    border: 1px solid #ccc; /* Optional: Add a border for visibility */
    height: 150px;
    margin-top: -30px;
}
.guarantee{
    z-index: 10;
    text-align: center;
    position:static;
    justify-content: center;
    display: flex;
}
.guara{
    background-color: #232323;
    width: 50%;
    text-align: center;
}
.imgWap{
    width: 100%;
    height: 300px;
}
.imgWap > img{
    width:80%;
    height: 100%;
    border-radius: 15px;
}
.dReview{
  display: flex;
}
.rHr{
  border-bottom: 1px solid #ECDBCB;
  width: 100%;
}
.rv1{
  border-bottom: 11px solid #ECDBCB;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 5px;
}
.rv2{
  border-bottom: 1px solid #ECDBCB;
  padding-bottom: 11px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;

}
.whatYouGet{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.dRv{
  width: 300px;
  display: grid;
  place-items: center;
}
.review{
  padding: 1%
}
.reviewForm{
  padding: 5% 3% ;
  border: 1px solid #ECDBCBB0;
  border-radius: 15px;
}
.submitBtn{
  width: 80%;
  background-color: #E3D081;
  margin-top: 5%;
  font-weight: 600;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 10%;
  border-radius: 15px;
}
.revlabel{
  color: #E3D081;
  padding-bottom: 1%;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
}


.formIn, .formIn:focus, .formIn:active, .formTextArea, .formTextArea:focus, .formTextArea:active{
    background: #D9D9D9;
    border: 1px solid #555555;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;
    height: 80px;
    padding:0.5rem;
    color: #000000;
    font-size: 2rem;
}
.formTextArea, .formTextArea:focus, .formTextArea:active{
    height: 100%;
}

.colCd{
  margin: 0;
}



.colCard2{
  border: 1px solid #ECDBCBB0;
  border-radius: 15px;
  padding: 5%;
  padding-top: 1.5%;
}
.colCardTop2{
  height: 352px;
}
.colCardTopImg{
  height: 100%;
}
.colCardBottom2{
  border: none;
  padding: 0;
}
.cusReview{
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
}
.cusRev{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.relatedP{
  padding-top: 5%;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .cartBtns{
    padding-right: 0;
  }
  .cartImg{
      width: 100%;
      height: 500px;
      border-radius: 15px;
  }
  .cartTopRight{
    padding-top: 5%;
  }
  .leftRight{
    width: 95%;
    padding-left: 5%;
    padding-right: 5%
  }
  select{
    width: 100%;
  }
  .cartBtn{
    font-size: 15px;
  }
  .rv1{
      border-bottom: 7px solid #ECDBCB;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 5px;
    }
  .rv2{
      font-size: 1rem;
      font-weight: 500;
    }
    .whatYouGet{
      font-family: Poppins;
      font-size: 15px;
      font-weight: 500;
      line-height: 10px
    }
    .dBtn2{
          width:100%;
          height: 60px;
          font-size: 15px;
          padding: 0%;
      }
      .addCartBottom{
        height: 300px;
      }
      .imgWap{
        height: 200px;
      }
      .formIn, .formIn:focus, .formIn:active, .formTextArea, .formTextArea:focus, .formTextArea:active{
      height: 40px;
      font-size: 1rem;
      }
      .formTextArea, .formTextArea:focus, .formTextArea:active{
      height: 100px;
      }
      .submitBtn{
        font-size: 1rem;
        line-height: 3rem;
      }
      .revlabel{
        font-size: 1rem;
      }
      .relatedP{
        font-size: 2rem;
      }
      .colCd{
        margin-bottom: 15px;
      }
}